// 应用权限
export const applicationPageData = {
  "code": 200,
  "success": true,
  "data": {
    "current": 1,
    "size": 10,
    "total": 10,
    "pages": 1,
    "records": [
      {
        "applicationId": 45,
        "applicationName": "播款网-微信小程序",
        "applicationAbbreviation": null,
        "applicationCompany": "杭州云屾科技有限公司",
        "applicationCellphone": "15874859365",
        "applicationEmail": "95632323235@qq.com",
        "applicationAccessKey": 1014837,
        "applicationSubmitTime": "2021-08-30 14:40:39",
        "applicationSubmitAdminId": 28,
        "applicationLastUpdateTime": null,
        "applicationLastUpdateAdminId": null,
        "applicationState": "y",
        "applicationIsDelete": "n"
      },
      {
        "applicationId": 44,
        "applicationName": "猫耳客户前台",
        "applicationAbbreviation": null,
        "applicationCompany": "杭州西璧网络科技有限公司",
        "applicationCellphone": "15963524789",
        "applicationEmail": "459895623232@sina.com",
        "applicationAccessKey": 1014119,
        "applicationSubmitTime": "2021-08-30 14:40:15",
        "applicationSubmitAdminId": 28,
        "applicationLastUpdateTime": null,
        "applicationLastUpdateAdminId": null,
        "applicationState": "y",
        "applicationIsDelete": "n"
      },
      {
        "applicationId": 43,
        "applicationName": "得体",
        "applicationAbbreviation": null,
        "applicationCompany": "杭州得体科技有限公司",
        "applicationCellphone": "19658741236",
        "applicationEmail": "26568956232@qq.com",
        "applicationAccessKey": 1014037,
        "applicationSubmitTime": "2021-08-30 14:39:54",
        "applicationSubmitAdminId": 28,
        "applicationLastUpdateTime": null,
        "applicationLastUpdateAdminId": null,
        "applicationState": "y",
        "applicationIsDelete": "n"
      },
      {
        "applicationId": 42,
        "applicationName": "订货会系统",
        "applicationAbbreviation": null,
        "applicationCompany": "杭州亿尚智能科技有限公司",
        "applicationCellphone": "14589896566",
        "applicationEmail": "58965687566@163.com",
        "applicationAccessKey": 1013937,
        "applicationSubmitTime": "2021-08-30 14:39:36",
        "applicationSubmitAdminId": 28,
        "applicationLastUpdateTime": null,
        "applicationLastUpdateAdminId": null,
        "applicationState": "y",
        "applicationIsDelete": "n"
      },
      {
        "applicationId": 41,
        "applicationName": "衣加加-微信小程序",
        "applicationAbbreviation": null,
        "applicationCompany": "杭州亿尚智能科技有限公司",
        "applicationCellphone": "19658746523",
        "applicationEmail": "5689866565@qq.com",
        "applicationAccessKey": 1013923,
        "applicationSubmitTime": "2021-08-30 14:39:09",
        "applicationSubmitAdminId": 28,
        "applicationLastUpdateTime": null,
        "applicationLastUpdateAdminId": null,
        "applicationState": "y",
        "applicationIsDelete": "n"
      },
      {
        "applicationId": 40,
        "applicationName": "衣加加-App",
        "applicationAbbreviation": null,
        "applicationCompany": "杭州亿尚智能科技有限公司",
        "applicationCellphone": "19658741325",
        "applicationEmail": "pengh@163.com",
        "applicationAccessKey": 1013900,
        "applicationSubmitTime": "2021-08-30 14:38:47",
        "applicationSubmitAdminId": 28,
        "applicationLastUpdateTime": null,
        "applicationLastUpdateAdminId": null,
        "applicationState": "y",
        "applicationIsDelete": "n"
      },
      {
        "applicationId": 39,
        "applicationName": "乔司时尚地图-微信小程序",
        "applicationAbbreviation": null,
        "applicationCompany": "杭州亿尚智能科技有限公司",
        "applicationCellphone": "15748593241",
        "applicationEmail": "1965471522@qq.cm",
        "applicationAccessKey": 1012979,
        "applicationSubmitTime": "2021-08-30 14:38:14",
        "applicationSubmitAdminId": 28,
        "applicationLastUpdateTime": null,
        "applicationLastUpdateAdminId": null,
        "applicationState": "y",
        "applicationIsDelete": "n"
      },
      {
        "applicationId": 38,
        "applicationName": "综合体数字云仓-web",
        "applicationAbbreviation": null,
        "applicationCompany": "杭州亿尚智能科技有限公司",
        "applicationCellphone": "15963254178",
        "applicationEmail": "248596741@qq.com",
        "applicationAccessKey": 1012075,
        "applicationSubmitTime": "2021-08-30 14:37:43",
        "applicationSubmitAdminId": 28,
        "applicationLastUpdateTime": null,
        "applicationLastUpdateAdminId": null,
        "applicationState": "y",
        "applicationIsDelete": "n"
      },
      {
        "applicationId": 37,
        "applicationName": "数据大屏-DATaV",
        "applicationAbbreviation": null,
        "applicationCompany": "杭州亿尚智能科技有限公司",
        "applicationCellphone": "15965474852",
        "applicationEmail": "248574563@163.com",
        "applicationAccessKey": 1011748,
        "applicationSubmitTime": "2021-08-30 14:37:12",
        "applicationSubmitAdminId": 28,
        "applicationLastUpdateTime": null,
        "applicationLastUpdateAdminId": null,
        "applicationState": "y",
        "applicationIsDelete": "n"
      },
      {
        "applicationId": 36,
        "applicationName": "服装产业云平台-web",
        "applicationAbbreviation": "综合体官网",
        "applicationCompany": "杭州亿尚智能科技有限公司",
        "applicationCellphone": "18356478596",
        "applicationEmail": "YSZN@FZZHT.COM",
        "applicationAccessKey": 1011592,
        "applicationSubmitTime": "2021-08-30 14:35:18",
        "applicationSubmitAdminId": 28,
        "applicationLastUpdateTime": null,
        "applicationLastUpdateAdminId": null,
        "applicationState": "y",
        "applicationIsDelete": "n"
      }
    ]
  },
  "msg": "操作成功"
}

export const applicationDetailData = {
  "code": 200,
  "success": true,
  "data": {
    "applicationId": 45,
    "applicationName": "播款网-微信小程序",
    "applicationAbbreviation": '播款',
    "applicationCompany": "杭州云屾科技有限公司",
    "applicationCellphone": "15874859365",
    "applicationEmail": "95632323235@qq.com",
    "applicationAccessKey": 1014837,
    "applicationSubmitTime": "2021-08-30 14:40:39",
    "applicationSubmitAdminId": 28,
    "applicationLastUpdateTime": null,
    "applicationLastUpdateAdminId": null,
    "applicationState": "y",
    "applicationIsDelete": "n"
  },
  "msg": "操作成功"
}

export const certificateListData = {
  "code": 200,
  "success": true,
  "data": [
    {
      "certificateId": 29,
      "applicationId": 45,
      "certificateKey": "Y0z8PNUhNUY8sULzbXY5492Ghb1bEE4G",
      "certificateMemo": "备用密钥",
      "certificateSubmitTime": "2021-08-30 15:20:29",
      "certificateSubmitAdminId": 25,
      "certificateLastUpdateTime": "2021-08-30 15:20:43",
      "certificateLastUpdateAdminId": 25,
      "certificateState": "y",
      "certificateIsDelete": "n"
    },
    {
      "certificateId": 28,
      "applicationId": 45,
      "certificateKey": "DEGC7htY7V0s2G1W7LXW9XYC9GkCDFms",
      "certificateMemo": "当前密钥",
      "certificateSubmitTime": "2021-08-30 15:19:36",
      "certificateSubmitAdminId": 25,
      "certificateLastUpdateTime": "2021-08-30 15:20:53",
      "certificateLastUpdateAdminId": 25,
      "certificateState": "y",
      "certificateIsDelete": "n"
    }
  ],
  "msg": "操作成功"
}