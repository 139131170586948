import createRequest from '@/utils/request'
import API_PREFIX from '@/config/api.prefix'

import { applicationPageData, applicationDetailData, certificateListData } from './test-data/01.js'

// @params:  baseUrl, accessKey, secretKey
const request = createRequest(API_PREFIX.open_base_url)

// 应用注册 - 接口列表
const apis = {
    // 应用注册 - 分页
    applicationPage: '/application/open-platform-application/page',
    // 应用注册 - 新增
    applicationSave: '/application/open-platform-application/save',
    // 应用注册 - 详情
    applicationDetail: '/application/open-platform-application/detail',
    // 应用注册 - 修改
    applicationUpdate: '/application/open-platform-application/update',
    // 应用注册 - 删除
    applicationRemove: '/application/open-platform-application/remove',
    // 应用接入凭证（秘钥） - 列表
    certificateList: '/application/open-platform-certificate/list',
    // 应用接入凭证（秘钥） - 新增
    certificateSave: '/application/open-platform-certificate/save',
    // 应用接入凭证（秘钥） - 修改状态
    certificateUpdateState: '/application/open-platform-certificate/updateStateById',
    // 应用接入凭证（秘钥） - 删除
    certificateRemove: '/application/open-platform-certificate/remove',
    // 应用接入凭证（秘钥） - 修改备注
    certificateUpdateMemo: '/application/open-platform-certificate/updateMemoById',
}

// 应用注册 - 分页
// export function applicationPage(parameter) {
//     return request({
//         url: apis.applicationPage,
//         method: 'get',
//         params: parameter
//     })
// }

export function applicationPage(parameter) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(applicationPageData)
        }, 300)
    }) 
}

// 应用注册 - 新增
export function applicationSave(parameter) {
    return request({
        url: apis.applicationSave,
        method: 'post',
        data: parameter
    })
}
// 应用注册 - 详情
// export function applicationDetail(parameter) {
//     return request({
//         url: apis.applicationDetail,
//         method: 'get',
//         params: parameter
//     })
// }
export function applicationDetail(parameter) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(applicationDetailData)
        }, 300)
    })
}


// 应用注册 - 修改
export function applicationUpdate(parameter) {
    return request({
        url: apis.applicationUpdate,
        method: 'post',
        data: parameter
    })
}

// 应用注册 - 删除
export function applicationRemove(parameter) {
    return request({
        url: apis.applicationRemove,
        method: 'post',
        data: parameter
    })
}

// 应用接入凭证（秘钥） - 列表
// export function certificateList(parameter) {
//     return request({
//         url: apis.certificateList,
//         method: 'get',
//         params: parameter
//     })
// }

export function certificateList(parameter) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(certificateListData)
        }, 300)
    })
}

// 应用接入凭证（秘钥） - 新增
export function certificateSave(parameter) {
    return request({
        url: apis.certificateSave,
        method: 'post',
        data: parameter
    })
}

// 应用接入凭证（秘钥） - 修改状态
export function certificateUpdateState(parameter) {
    return request({
        url: apis.certificateUpdateState,
        method: 'post',
        data: parameter
    })
}

// 应用接入凭证（秘钥） - 修改备注
export function certificateUpdateMemo(parameter) {
    return request({
        url: apis.certificateUpdateMemo,
        method: 'post',
        data: parameter
    })
}

// 应用接入凭证（秘钥） - 删除
export function certificateRemove(parameter) {
    return request({
        url: apis.certificateRemove,
        method: 'post',
        data: parameter
    })
}
