<template>
  <div>
    <div class="no-detail" v-if="noDetail">
      内容不存在或者已被删除
    </div>
    <page-head-wrapper v-else>
      <!-- 操作栏 -->
      <template v-slot:handles-btns>
        <a-button style="margin-right: 16px" @click="deleteHandle"><i class="e-icon delete"></i>删除</a-button>
        <a-button @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
      </template>
      <a-spin :spinning="loading">
        <div class="mp-detail-module">
          <div class="module-top">
            <h3 class="mod-ttile">应用详情</h3>
            <div class="mod-handles">
              <a-button v-if="!canEditable" type="link" size="small" @click="editHandle"
              ><i class="e-icon edit-blue"></i>编辑</a-button>
              <template v-else>
                <a-button type="link" size="small" @click="onSubmit">保存</a-button>
                <a-button type="link" size="small" class="grey ml-8" @click="canEditable = false">取消</a-button>
              </template>
            </div>
          </div>
          <div class="module-main bdm">
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-row :gutter="16">
                <a-col :span="8">
                  <a-form-model-item label="应用名称" required>
                    <span class="item-text">{{detailData.applicationName}}</span>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="应用简称">
                    <span class="item-text" v-if="!canEditable">{{detailData.applicationAbbreviation}}</span>
                    <a-input v-else
                      v-model="form.applicationAbbreviation"
                      placeholder="请输入应用简称"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="AccessKey">
                    <span class="item-text">{{detailData.applicationAccessKey}}</span>
                  </a-form-model-item>
                </a-col>
              </a-row>

              <a-row :gutter="16">
                <a-col :span="8">
                  <a-form-model-item label="所属企业" prop="applicationCompany">
                    <span class="item-text" v-if="!canEditable">{{detailData.applicationCompany}}</span>
                    <a-input
                      v-else
                      v-model="form.applicationCompany"
                      placeholder="请输入所属企业"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="联系电话" prop="applicationCellphone">
                    <span class="item-text" v-if="!canEditable">{{detailData.applicationCellphone}}</span>
                    <a-input
                      v-else
                      v-model="form.applicationCellphone"
                      placeholder="请输入联系电话"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="联系邮箱" prop="applicationEmail">
                    <span class="item-text" v-if="!canEditable">{{detailData.applicationEmail}}</span>
                    <a-input
                      v-else
                      v-model="form.applicationEmail"
                      placeholder="请输入联系邮箱"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row  :gutter="16">
                <a-col :span="8">
                  <a-form-model-item label="应用状态" prop="applicationState">
                    <span class="item-text" v-if="!canEditable">{{detailData.applicationState == 'y' ? '启用' : '禁用'}}</span>
                    <a-radio-group v-model="form.applicationState" v-else>
                      <a-radio value="y">启用</a-radio>
                      <a-radio value="n">禁用</a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
  
        </div>
      </a-spin>

      <keyManagement v-if="keyManagementShow"/>
    </page-head-wrapper>
  </div>
</template>

<script>
import {applicationDetail, applicationUpdate, applicationRemove} from '@/api/app-permissions'
import {validateMobile, validateEmail} from '@/utils/validate'
import {mapGetters} from 'vuex'
import keyManagement from './key-management'
let validatePhone = (rule, value, callback) => {
  value = value.trim()
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else if (!validateMobile(value)) {
    callback(new Error('手机号格式不正确'))
  } else {
    callback();
  }
}
let validateApplicationEmail = (rule, value, callback) => {
  value = value.trim()
  if (value === '') {
    callback(new Error('请输入邮箱'))
  } else if (!validateEmail(value)) {
    callback(new Error('邮箱格式不正确'))
  } else {
    callback();
  }
}
export default {
  name: 'app-registry-detail', 
  keep_alive_tab: true,
  computed: {
    ...mapGetters(['adminId']),
  },
  components: {
    keyManagement
  },
  data() {
    return {
			loading: false,
      canEditable: false, // 详情编辑

      labelCol: { lg: {span: 8}, xl: {span: 6}, xxl: {span: 4} },
      wrapperCol: { lg: {span: 16}},
      other: "",
			detailData: {},
      form: {
				applicationName: '',
				applicationAbbreviation: '',
				applicationAccessKey: '',
				applicationCompany: '',
				applicationCellphone: '',
				applicationEmail: '',
				applicationState: '',
      },
      rules: {
        applicationCompany: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        applicationCellphone: [
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
        applicationEmail: [
          { required: true, validator: validateApplicationEmail, trigger: 'blur' },
        ],
      },
      id: '',

      noDetail: false,
      keyManagementShow: true
    };
  },
	created() {
    console.log('detail: created')
    console.log('this.id', this.id )
    if (this.$route.name == 'app-registry-detail' && this.$route.query.id && this.id !== this.$route.query.id ) {
      this.id = this.$route.query.id
      this.getDetail()
    }
	},
  mounted() {
    console.log('detail: mounted')
  },
  activated() {
    console.log('detail: activated')
  },
  deactivated() {
    console.log('detail: deactivated')
  },
  watch: {
    // '$route': {
    //   handler: function(to, from) {
    //     console.log('detail - $route', to, from)
    //     console.log('this.id', this.id)
    //     if (to.name == 'app-registry-detail' && to.query.id && this.idArr.indexOf(to.query.id) < 0 ) {
    //       this.id = to.query.id
    //       this.idArr.push(this.id)
    //       console.log('this.id', this.id)
    //       this.getDetail()
    //     }
    //   },
    //   immediate: true
    // }
  },
  methods: {
    onRefresh() {
      this.getDetail()
      this.keyManagementShow = false
      this.$nextTick(() => {
        this.keyManagementShow = true
      })
    },
		getDetail() {
			this.loading = true
			applicationDetail({
				applicationId: this.id
			}).then(res => {
				console.log(res)
				if (res.code === 200) {
          if (res.data) {
            this.noDetail = false
            this.detailData = res.data
            this.$store.dispatch('updateTabTitle', {
              force: true,
              path: this.$route.fullPath,
              title: this.detailData.applicationName
            })
          } else {
            this.noDetail = true
          }
					
				} else {
					this.$message.error(res.msg || '网络异常，请稍后重试')
				}
			}).catch(err => {
				console.warn(err)
			}).finally(() => {
				this.loading = false
			})
		},
    editHandle() {
      this.canEditable = true
      this.form.applicationName = this.detailData.applicationName
      this.form.applicationAbbreviation = this.detailData.applicationAbbreviation
      this.form.applicationAccessKey = this.detailData.applicationAccessKey
      this.form.applicationCompany = this.detailData.applicationCompany
      this.form.applicationCellphone = this.detailData.applicationCellphone
      this.form.applicationEmail = this.detailData.applicationEmail
      this.form.applicationState = this.detailData.applicationState
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
					console.log(this.form)
          console.log("submit!");
          this.loading = true
          applicationUpdate({
            ...this.form,
            applicationId: this.detailData.applicationId,
            applicationLastUpdateAdminId: this.adminId
          }).then(res=> {
            if (res.code === 200) {
              this.$message.success('保存成功')
              this.canEditable = false
              this.getDetail()
              
              // setTimeout(() => {
              //   this.$router.go(-1)
              // }, 1000)
            } else {
              this.$message.error(res.msg || '网络异常，请稍后重试')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    toBack() {
      this.$router.go(-1);
    },
    // 删除
    deleteHandle() {
      this.$confirm({
        title: '确定删除此应用信息?',
        // content: h => <div>Some descriptions</div>,
        centered: true,
        onOk: () => {
          console.log('OK');
          applicationRemove({
            ids: this.id,
            applicationLastUpdateAdminId: this.adminId
          }).then(res=> {
            if (res.code === 200) {
              this.$message.success('删除成功')
              // this.currentRoutePush()
              this.closeTabPage()
            } else {
              this.$message.error(res.msg || '网络异常，请稍后重试')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.saveFormLoading = false
          })
        },
        onCancel: () =>{
          console.log('Cancel');
        },
      })
    }
  },
};
</script>

<style lang="less" scoped>
.no-detail {
  background: #fff;
  border: 1px solid #DCDEE3;
  border-top: none;
  padding: 100px 32px;
  text-align: center;
}
.item-text {
	color: #333;
	line-height: 22px;
}
.bottom-btn-wrap {
  padding: 40px 0;
}
</style>